
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-weight: 400;
  font-family: 'CeraPro';
  src: url(./assets/fonts/CeraPro-Regular.ttf);
}
@font-face {
  font-weight: 500;
  font-family: 'CeraPro';
  src: url(./assets/fonts/CeraPro-Medium.ttf);
}
@font-face {
  font-weight: 600;
  font-family: 'CeraPro';
  src: url(./assets/fonts/CeraPro-Bold.ttf);
}
@font-face {
  font-weight: 800;
  font-family: 'CeraPro';
  src: url(./assets/fonts/CeraPro-Black.ttf);
}
@font-face {
  font-weight: 400;
  font-family: 'CeraPro';
  font-style: italic;
  src: url(./assets/fonts/CeraPro-RegularItalic.ttf);
}
@font-face {
  font-weight: 500;
  font-family: 'CeraPro';
  font-style: italic;
  src: url(./assets/fonts/CeraPro-MediumItalic.ttf);
}
@font-face {
  font-weight: 600;
  font-family: 'CeraPro';
  font-style: italic;
  src: url(./assets/fonts/CeraPro-BoldItalic.ttf);
}
@font-face {
  font-weight: 800;
  font-family: 'CeraPro';
  font-style: italic;
  src: url(./assets/fonts/CeraPro-BlackItalic.ttf);
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

body {
  /* background-color: #68a0c0; */
  /* background: url('./assets/background.svg') no-repeat center center rgb(241 245 249); */
  /* background-size: cover; */
  font-family: 'CeraPro', sans-serif;
  width: 100%;
  /* min-height: 100vh; */
}

.bg-gradient {
  background: linear-gradient(180deg, #a0dbed 0%, #68a0c0 100%);
}

.trees {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: url('./assets/bg.png') no-repeat center bottom;
  background-size: 100% auto;
}

@media (min-width: 1024px) {
  .full-container-h {
    min-height: calc(100vh - 112px);
  }
}


/* .octagons {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
}
.octagons.left {
  background: url('./assets/octogons_left.svg') no-repeat left center;
  background-size: auto 100%;
}
.octagons.right {
  background: url('./assets/octogons_right.svg') no-repeat right center;
  background-size: auto 100%;
} */

#root {
  position: relative;
}

#starfield {
  left:0;
  top:0;
  position: fixed;
  width: 100%;
  height: 100%;
}

.animate-show-in-opacity {
  animation: show-in-opacity 5s ease both;
}

.animate-show-in-from-top {
  animation-name: show-in-from-top;
  animation-duration: 1.5s;
  animation-timing-function: ease;
  animation-fill-mode: both;
}

.animate-swipe-left {
  animation: swipe-left 2s linear infinite;
}

@keyframes swipe-left {
  0%{
    transform: translate(0,0)
  }
  100%{
    transform: translate(-128px,0)
  }
}

.animation-delay-100 {
  animation-delay: 100ms;
}
.animation-delay-150 {
  animation-delay: 150ms;
}
.animation-delay-200 {
  animation-delay: 200ms;
}
.animation-delay-250 {
  animation-delay: 250ms;
}
.animation-delay-300 {
  animation-delay: 300ms;
}
.animation-delay-350 {
  animation-delay: 350ms;
}
.animation-delay-400 {
  animation-delay: 400ms;
}
.animation-delay-450 {
  animation-delay: 450ms;
}
.animation-delay-500 {
  animation-delay: 500ms;
}
.animation-delay-600 {
  animation-delay: 600ms;
}
.animation-delay-700 {
  animation-delay: 700ms;
}

@keyframes show-in-opacity {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

@keyframes show-in-from-top {
  0%{
    opacity: 0;
    transform: translateY(-24px);
  }
  100%{
    opacity: 1;
    transform: translateY(0);
  }
}

/* 
.scrollbar::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: rgb(17, 24, 39);
}

.scrollbar::-webkit-scrollbar-thumb {
  background: rgb(250, 204, 21);
  border-radius: 100vh;
  border: 3px solid rgb(17, 24, 39)
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgb(229, 186, 13)
} */